
// Components
import RatingList from 'portal/components/common/rating/RatingList';
import RatingRequest from 'portal/components/common/rating/RatingRequest';
import RatingNews from 'portal/components/common/rating/RatingNews';

export default {
    name: 'HomeRating',
    components: { RatingNews, RatingRequest, RatingList },
    props: {
        companies: {
            type: Array,
            default: () => [],
        },

        news: {
            type: Array,
            default: () => [],
        },
    },
};
