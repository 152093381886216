
import VButtonNew from 'portal/components/ui/button/VButtonNew';
import { mapState } from 'vuex';

const RatingDeclareCompanyModal = () => import('portal/components/pages/rating/RatingDeclareCompanyModal.vue');
const AuthModal = () => import('portal/components/modals/auth/AuthModal.vue');
const bannerData = {
    title: 'Заявите компанию <br> в рейтинг сейчас!',
    buttonText: 'Заявить компанию',
    backgroundImage: 'https://dvizh-media-dev.storage.yandexcloud.net/media/static/rating/2.png',
};

export default {
    name: 'RatingRequest',
    components: { VButtonNew },

    computed: {
        ...mapState({
            user: state => state.user?.user,
        }),

        getBannerData() {
            return bannerData;
        },

        backgroundImage() {
            return {
                'background-image': `url('${this.getBannerData.backgroundImage}')`,
            };
        },
    },

    methods: {
        /**
         * Происходит проверка авторизованности и в зависимости от наличия авторизованности будет открываться соответствующая
         * модалка с соответствующим позиционирование модалки
         */
        handleOpenModal() {
            const modal = {
                component: this.user ? RatingDeclareCompanyModal : AuthModal,
                position: this.user ? 'right' : 'center',
            };

            this.$modal.open(modal.component, {
                position: modal.position,
            });
        },
    },
};
