
import VIcon from 'portal/components/ui/icon/VIcon';
const buttonData = {
    text: 'Смотреть топ-100',
    link: '/rating',
};

const ratingTableData = {
    title: 'Топ-100 девелоперов',
    description: 'Рейтинг девелоперов России 2024',
    tableHeader: ['#', 'Девелопер', 'Голосов'],
};

export default {
    name: 'RatingList',
    components: { VIcon },
    props: {
        companies: {
            type: Array,
            default: () => [],
        },

        btnColor: {
            type: String,
            default: 'base-500-0',
        },

        navLinkTo: {
            type: String,
            default: '',
        },


        btnText: {
            type: String,
            default: 'Смотреть топ-100',
        },

    },

    computed: {
        isTablet() {
            return this.$deviceIs.mobile || this.$deviceIs.tablet;
        },

        isSquareBtn() {
            return this.size === 'small' && this.isTablet;
        },

        getRatingTableData() {
            return ratingTableData;
        },

        getButtonData() {
            return buttonData;
        },
    },
};
