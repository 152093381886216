import { formatDateTime } from '@@/common/assets/js/utils/date-time-utils';

export function getNewsPubDate(pub_date) {
    const pubDate = new Date(pub_date);
    const now = new Date();
    const today = formatDateTime(pubDate, '$j $b $y') === formatDateTime(now, '$j $b $y');
    const yesterday = formatDateTime(pubDate, '$j $b $y') === formatDateTime(new Date(now.getTime() - 24 * 60 * 60 * 1000), '$j $b $y');
    const time = formatDateTime(pubDate, '$H:$I');

    if (today) {
        const minutesAgo = Math.floor((now - pubDate) / 60000);
        if (minutesAgo < 60) {
            return `${minutesAgo} минут назад`;
        } else {
            return time;
        }
    } else if (yesterday) {
        return formatDateTime(pubDate, '$j $b, $H:$I');
    } else {
        return formatDateTime(pubDate, '$j $b, $H:$I');
    }
}
