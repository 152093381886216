
import { getNewsPubDate } from 'portal/assets/js/utils/get-news-pub-date';
import ContentCard from 'portal/components/common/content-card/ContentCard';
export default {
    name: 'RatingNews',

    components: {
        ContentCard,
    },

    props: {
        news: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        getDate(pubDate) {
            getNewsPubDate(pubDate);
        },
    },
};
