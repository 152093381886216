// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RatingRequest_CIlqH{background-blend-mode:luminosity;background-color:#17181c;background-position:100% 100%;background-repeat:no-repeat;background-size:contain;border-radius:2rem;display:grid;gap:9vw;grid-area:banner;overflow:hidden;padding:2.4rem}.button_nNaOH{width:-webkit-max-content;width:max-content}.button_nNaOH.v-button .v-button__label{color:#17181c}.title_0FlqL{color:#fff;font-size:2.8rem;font-weight:500;letter-spacing:-.112rem;line-height:3.08rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"RatingRequest": "RatingRequest_CIlqH",
	"button": "button_nNaOH",
	"title": "title_0FlqL"
};
module.exports = ___CSS_LOADER_EXPORT___;
